import Image from "next/image";
import Link from "next/link";
import { FaRegCalendarCheck, FaFolder } from "react-icons/fa6";
import { IoSchoolOutline } from "react-icons/io5";
import { HiOutlineShieldCheck } from "react-icons/hi2";
import { MdOutlineQrCodeScanner } from "react-icons/md";

export default function Hero() {
  return (
    <div className="lg:pt-20 bg-[#00718F]">
      <div className="min-h-[40rem] relative mx-auto 3xl:w-[85%] lg:w-[80%] overflow-clip">
        <div className="grid grid-cols-1 min-[1116px]:grid-cols-2 px-4">
          <div className="min-[1116px]:pr-20 mt-32 px-0 sm:px-16 min-[1116px]:px-0">
            <h4 className="font-bold lg:text-4xl text-[24px] text-white tracking-[0.72px] lg:text-center min-[1116px]:text-left">Digitalisasi Sistem Manajemen Pendidikan</h4>
            <p className="tracking-wide text-white leading-relaxed mt-2 lg:text-center min-[1116px]:text-left">
              Layanan lengkap manajemen data siswa, staff/guru, PPDB online, administrasi, keuangan, akademik presensi, tabungan, hingga transaksi non tunai di kantin lembaga pendidikan (Sekolah dan Pondok Pesantren)
            </p>
            <div className="flex sm:flex-row gap-2 tracking-wider mt-4 items-center justify-center min-[1116px]:justify-start">
              <Link href="/#features" className="btn btn-accent text-white rounded-full lg:w-52 !font-semibold shadow-lg shadow-accent/70">
                Selengkapnya
              </Link>
              <a
                href="https://www.jotform.com/240072160338447"
                target="_blank"
                rel="noopener noreferrer"
                className="btn bg-tprimary-4 border-tprimary-4 hover:bg-tprimary-4/90 hover:border-tprimary-4/90 text-white rounded-full lg:w-52 !font-semibold"
              >
                <FaRegCalendarCheck className="w-5 h-5" />
                Jadwalkan Demo
              </a>
            </div>
            {/* <div className="flex flex-col sm:flex-row gap-4 py-8 items-center justify-center min-[1116px]:justify-start">
              <div className="flex items-end gap-2">
                <div className="w-12 h-12 bg-tsecondary-2 rounded-lg shadow-lg shadow-tsecondary-2/70 text-white flex justify-center items-center">
                  <IoSchoolOutline className="w-6 h-6" />
                </div>
                <div className="flex flex-col">
                  <span className="font-medium tracking-widest text-sm text-white/80">TOTAL SEKOLAH</span>
                  <span className="font-black text-white/80">380+</span>
                </div>
              </div>
              <div className="flex items-end gap-2">
                <div className="w-12 h-12 bg-taccent-3 rounded-lg shadow-lg shadow-taccent-3/70 text-white flex justify-center items-center">
                  <IoSchoolOutline className="w-6 h-6" />
                </div>
                <div className="flex flex-col">
                  <span className="font-medium tracking-widest text-sm text-white/80">PENGGUNA CARDS</span>
                  <span className="font-black text-white/80">130.000+</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="flex justify-center">
            <Image loading="eager" src="/image/heroimageraw.png" width={500} height={500} className="block min-[1116px]:hidden z-10" alt="hero image" />
          </div>
        </div>
        <div className="max-[1115px]:hidden">
          <div className="absolute -bottom-14 right-32 bg-[#026079] rounded-full bg-opacity-65 w-[37rem] h-[35rem]"></div>
          <img src="/image/hero.png" className="absolute h-[550px] -bottom-0 right-16 z-10"></img>
          <div className="z-20 absolute right-[35rem] bottom-[8rem] bg-white bg-opacity-80 p-3 rounded-xl flex items-center gap-2 text-dark-muted max-w-[15rem]">
            <div className="bg-accent p-2 rounded-lg text-white h-full">
              <MdOutlineQrCodeScanner className="w-5 h-5" />
            </div>
            <span className="text-opacity-80 tracking-tight text-sm">Berbagai cara dan metode pembayaran.</span>
          </div>
          <div className="z-20 absolute top-80 right-36 bg-white bg-opacity-80 p-3 rounded-xl flex items-center gap-2 text-dark-muted max-w-[15rem]">
            <div className="bg-tsecondary-3 p-2 rounded-lg text-white h-full">
              <FaFolder className="w-5 h-5" />
            </div>
            <span className="text-opacity-80 tracking-tight text-sm">Akses data serba lengkap</span>
          </div>
          <div className="absolute top-28 right-[32rem] bg-white bg-opacity-80 p-3 rounded-xl flex items-center gap-2 text-dark-muted max-w-[15rem]">
            <div className="bg-tprimary-4 p-2 rounded-lg text-white h-full">
              <HiOutlineShieldCheck className="w-5 h-5" />
            </div>
            <span className="text-opacity-80 tracking-tight text-sm">Transaksi pasti aman</span>
          </div>
        </div>
      </div>

      <div className="relative">
        <Image loading="eager" src="/image/shape.svg" width={500} height={500} alt="shape" className="absolute w-full -bottom-1 z-30" />
      </div>
    </div>
  );
}
