export default function getTestimonials(index) {
  let testi1 = [
    {
      name: "Bapak Ahmad",
      avatar: "/image/avatar/pakahmad.webp",
      description: "Cards telah membawa perubahan besar dalam operasional harian pesantren kami. Fitur-fitur terintegrasi mereka membantu kami mengelola administrasi, keuangan, dan interaksi dengan santri dengan lebih efisien.",
      school: "Pengurus Pesantren Al-Khair",
    },
    {
      name: "Ibu Fatimah",
      avatar: "/image/placeholder/avatar1.webp",
      description: "Sebagai pengurus pesantren, saya merasa terbantu dengan kemudahan yang diberikan oleh Cards. Dengan kartu santri digital, kami dapat mengontrol akses dan mengelola kegiatan santri dengan lebih terstruktur dan aman.",
      school: "Pengurus Pesantren Darussalam",
    },
    {
      name: "Ali",
      avatar: "/image/placeholder/avatar1.webp",
      description: "Kartu santri digital dari Cards membuat hidup di pesantren jadi lebih praktis. Dari absensi, izin keluar, sampai transaksi belanja di kantin, semuanya jadi lebih cepat dan mudah. Terima kasih Cards!",
      school: "Santri Pesantren Baitul Ma'rifat",
    },
    {
      name: "Lembaga",
      avatar: "/image/placeholder/avatar1.webp",
      description: "Cards telah membantu kami mengoptimalkan pengelolaan keuangan sekolah. Dengan fitur-fitur seperti pembayaran online dan laporan keuangan yang terperinci, kami dapat mengelola keuangan dengan lebih efisien.",
      school: "SDN 1 Kartika Jaya",
    },
  ];

  let testi2 = [
    {
      name: "Zahra",
      avatar: "/image/avatar/zahra.webp",
      description: "Saya sangat senang dengan kemudahan yang diberikan oleh kartu santri digital. Sekarang saya tidak perlu lagi repot membawa uang tunai atau mencatat izin secara manual. Semuanya ada di ujung jari saya!",
      school: "Santri Pesantren Al-Hikmah",
    },
    {
      name: "Ibu Rini",
      avatar: "/image/placeholder/avatar1.webp",
      description: "Aplikasi Cards memberikan saya kemudahan untuk mengawasi aktivitas dan keuangan anak saya di pesantren. Dari pembayaran tagihan sampai melihat laporan kehadiran, semuanya bisa saya akses dengan mudah dan transparan.",
      school: "Orang Tua Santri Pesantren An-Nur",
    },
    {
      name: "Bapak Dodi",
      avatar: "/image/placeholder/avatar1.webp",
      description: "Kartu santri digital menjadi solusi yang sangat praktis bagi kami sebagai orang tua. Kami bisa melacak keberadaan anak kami dan memastikan kebutuhan mereka terpenuhi tanpa harus khawatir dengan keamanan uang tunai.",
      school: "Orang Tua Santri Pesantren Darul Falah",
    },
    {
      name: "Lembaga",
      avatar: "/image/placeholder/avatar1.webp",
      description: "Kami sangat terbantu dengan layanan pelanggan Cards. Tim dukungan mereka responsif dan membantu kami menyelesaikan setiap masalah dengan cepat. Kami merasa didukung sepanjang waktu.",
      school: "SMP Nurul Iman",
    },
  ];

  if (index === "all") return testi1.concat(testi2);
  return [testi1, testi2][index];
}
