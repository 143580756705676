import Image from "next/image";
import SectionMark from "../Misc/SectionMark";
import Link from "next/link";
import Cashless from "./Cashless";
import { FaCheckCircle } from "react-icons/fa";

export default function DetailedFeatures() {
  return (
    <section className="text-dark-muted flex flex-col justify-center items-center">
      <div className="text-center flex justify-center items-center flex-col gap-2 lg:px-24 p-2 pt-16">
        <h1 className="lg:text-[44px] lg:leading-none text-[24px] font-bold tracking-wide lg:w-[60%] w-full">
          Digitalisasi Lembaga Dimulai dengan <span className="text-primary">Sistem Cloud</span> Canggih
        </h1>
        <div className="flex justify-center">
          <p className="text-opacity-80 tracking-tight lg:w-[80%] w-full lg:text-[20px] text-[14px]">
            Data aman dengan kemudahan akses kapanpun melalui berbagai macam perangkat. Layanan berkualitas tinggi dengan banyak fitur tanpa investasi server dan pengembangan software.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center lg:px-20 px-4 lg:pb-0 pb-12">
        <div className="">
          <Image width={1000} height={1000} src="/image/lembaga.svg" alt="kartu siswa image" />
        </div>
        <div className="md:pl-8 md:mt-0 lg:mt-16">
          {/* <SectionMark>Efisien</SectionMark> */}
          <h1 className="font-bold tracking-wide lg:text-[30px] text-[24px]">Urusan administrasi selesai dalam satu Dashboard</h1>
          <p className="text-opacity-80 tracking-tight lg:text-[20px] text-[]">Dashboard manajemen yang multi akses untuk mengelola segala kegiatan yang ada di lembaga.</p>
          <ul className="mt-4 text-opacity-80 text-xs min-[424px]:text-sm md:text-base space-y-4 font-semibold">
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Pendaftaran peserta didik baru (PPDB Online)</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Manajemen database Siswa, Guru, Staff & Alumni</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Manajemen data tagihan</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Manajemen akademik</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Manajemen keuangan</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 py-12 bg-white lg:px-20 px-4 w-full">
        <div className="lg:ml-20">
          <SectionMark className={"mb-5"}>
            <p className="lg:text-[20px] text-[16px]">Orang Tua Gak Risau</p>
          </SectionMark>
          <h1 className="font-bold tracking-wide lg:text-[32px] text-[24px]">Aplikasi Orang Tua</h1>
          <h1 className="font-bold tracking-wide lg:text-[32px] text-[24px]">Bantu Memantau Aktivitas Anak</h1>
          <p className="text-opacity-80 tracking-tight mt-3 lg:text-[20px] text-[14px]">Terhubung real-time 24/7 antara orang tua, lembaga dan siswa.</p>
          <ul className="mt-4 text-opacity-80 text-xs min-[424px]:text-sm md:text-base space-y-4 font-semibold">
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Kelola uang saku anak</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Pantau progres pendidikan anak</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Monitor & bayar online tagihan sekolah</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Selalu mendapatkan informasi terbaru dari sekolah</span>
            </li>
            <li className="flex gap-1 items-center">
              <FaCheckCircle className="w-4 h-4 text-[#6DD3CE]" />
              <span className="lg:text-[20px] text-[14px]">Terlibat dalam pengkinian data anak dengan mudah</span>
            </li>
          </ul>

          <div className="lg:hidden block mt-5">
            <Image width={1000} height={1000} src="/image/aplikasi-orang-tua-mobile.png" alt="Aplikasi orang tua" />
          </div>

          <div className="buttons flex gap-4 lg:mt-12 mt-8 lg:justify-start justify-center">
            <Link href={"https://apps.apple.com/id/app/cards-kartu-digital/id1567875421"} target="__blank">
              <button className="flex gap-1 bg-black rounded-lg lg:w-[205px] w-full px-4 py-2">
                <div className="image flex justify-center items-center h-full">
                  <Image src={"/image/icon/apple-icon.png"} width={40} height={40} />
                </div>
                <div className="text-white text-left flex flex-col gap-1">
                  <p className="lg:text-[14px] text-xs">Download on the</p>
                  <h3 className="lg:text-[22px] text-sm font-semibold">App Store</h3>
                </div>
              </button>
            </Link>
            <Link href={"https://play.google.com/store/apps/details?id=id.cazh.cards.android&pli=1"} target="__blank">
              <button className="flex gap-1 bg-black rounded-lg lg:w-[205px] w-full px-4 py-2">
                <div className="image flex justify-center items-center h-full">
                  <Image src={"/image/icon/google-play-icon.png"} width={38} height={38} />
                </div>
                <div className="text-white text-left flex flex-col gap-1">
                  <p className="lg:text-[14px] text-xs">GET IT ON</p>
                  <h3 className="lg:text-[22px] text-sm font-semibold">Google Play</h3>
                </div>
              </button>
            </Link>
          </div>
        </div>
        <div className="lg:flex justify-center hidden">
          <div className="bg-[#6DD3CE] shadow-lg shadow-[#6DD3CE] rounded-[80px] w-[620px] flex justify-center items-center relative overflow-visible">
            <img src="/image/aplikasi-orang-tua.png" alt="aplikasi orang tua" className="absolute w-[800px] min-w-[800px] flex-shrink-0" />
          </div>
        </div>
      </div>

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 py-20 items-center">
        <div className="ml-10">
          <Image width={550} height={500} src="/image/dashboard.png" alt="dashboard image" />
        </div>
        <div className="md:pl-8 md:mt-0 mt-16">
          <SectionMark>Kemudahan</SectionMark>
          <h1 className="font-bold tracking-wide text-2xl">Aplikasi Lembaga</h1>
          <p className="text-opacity-80 tracking-tight">Satu dashboard untuk mengelola kegiatan akademik maupun keuangan</p>
          <ul className="list-image-[url(check.svg)] list-inside mt-4 text-opacity-80 text-xs min-[424px]:text-sm md:text-base space-y-4">
            <li>
              <span>
                <span className="font-bold lg:text-lg">Manajemen Siswa & Staff:</span> Sebagai master data, mengelola data siswa, alumni, staff dan guru. Termasuk pendaftaran, informasi kontak, dan catatan akademik.
              </span>
            </li>
            <li>
              <span>
                <span className="font-bold lg:text-lg">Manajemen Akademik:</span> Mempermudah guru dalam update progres pembelajaran, kehadiran, rapor dan konseling.
              </span>
            </li>
            <li>
              <span>
                <span className="font-bold lg:text-lg">Pembuatan Laporan:</span> Menyediakan fitur untuk membuat berbagai jenis laporan, seperti laporan kehadiran, laporan pembayaran, laporan keuangan dan laporan akademik.
              </span>
            </li>
            <li>
              <span>
                <span className="font-bold lg:text-lg">Komunikasi Internal:</span> Memfasilitasi komunikasi antara staf pendidikan, termasuk pengiriman pengumuman, pesan, dan tugas.
              </span>
            </li>
            <li>
              <span>
                <span className="font-bold lg:text-lg">Integrasi dengan Sistem Keuangan:</span> Terhubung dengan sistem keuangan lembaga untuk mengelola pembayaran tagihan, transaksi siswa, dan laporan keuangan.
              </span>
            </li>
          </ul>
        </div>
      </div> */}

      <Cashless />

      <div className="lg:px-24 w-[95%] px-2 py-12">
        <h1 className="lg:text-[60px] lg:block hidden font-bold lg:w-2/3 w-full mb-8">
          Siap <span className="text-primary">memulai</span> digitalisasi
        </h1>
        <h1 className="lg:text-[60px] lg:block hidden font-bold lg:w-2/3 w-full mb-12">lembaga, bersama kami?</h1>

        {/* Mobile header */}
        <h1 className="lg:hidden block text-[20px] mb-8 w-full font-bold">
          Siap <span className="text-primary">memulai</span> digitalisasi <br />
          lembaga, bersama kami?
        </h1>

        <div className="w-full bg-[#38BCB6] lg:p-16 p-4 rounded-xl flex flex-col md:flex-row justify-between items-center">
          <div className="lg:text-center md:text-left">
            <h1 className="text-white font-bold lg:text-[28px] text-[15px] tracking-wider lg:w-full w-[60%]">
              Diskusikan Bagaimana CARDS
              <br />
              Meningkatkan Efisiensi dan Kualitas Layanan Pendidikan Anda!
            </h1>

            <div className="buttons hidden mt-8 lg:flex gap-4 lg:justify-start justify-center">
              <a href={"/docs/dokumen-proposal.pdf"} download={true}>
                <button className="lg:text-[20px] lg:w-[235px] btn border-none text-white bg-gradient-to-bl from-[#00708D] to-[#05526A]">Download Proposal</button>
              </a>
              <a href={"https://www.jotform.com/240072160338447"} target="__blank">
                <button className="lg:text-[20px] lg:w-[188px] btn bg-[#E7F6F8] text-primary">Request Demo</button>
              </a>
            </div>
          </div>
          <div className="relative w-full overflow-visible flex justify-end">
            <img src={"/image/request-demo.png"} className="absolute lg:-top-[332px] -top-[208px] 3xl:left-[50%] lg:left-[30%] left-[55%] 3xl:-top-[353px] lg:w-[420px] w-[200px] lg:h-[520px] object-cover" />
          </div>
        </div>
        {/* Mobile buttons */}
        <div className="buttons lg:hidden w-full flex gap-2 justify-center items-center mt-12">
          <a href={"/docs/dokumen-proposal.pdf"} download={true} className="w-1/2">
            <button className="text-[14px] w-full btn border-none text-white bg-gradient-to-bl from-[#00708D] to-[#05526A]">Download Proposal</button>
          </a>
          <a href={"https://www.jotform.com/240072160338447"} target="__blank" className="w-1/2">
            <button className="text-[14px] btn bg-[#E7F6F8] text-primary w-full">Request Demo</button>
          </a>
        </div>
      </div>
    </section>
  );
}
